import { useState, useEffect, useContext } from 'react';
import { EventInput } from '@fullcalendar/react';
import { EuiSpacer } from '@elastic/eui';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

import { Screen } from 'types/screen';
import { Schedule } from 'types/schedule';
import EditorModal from './editModal';
import TimeGrid from 'components/scheduler/TimeGrid';
import './style.scss';

const TimeGridEditor = ({
  screen,
  setDateRange,
  editingSchedules,
  setEditingSchedules,
  backToSchedule,
  cancelChangeConfirm,
  handleChangeColorClick,
}: {
  screen: Screen;
  setDateRange: (after: Date, before: Date) => void;
  editingSchedules: Schedule[];
  setEditingSchedules: (schedules: Schedule[]) => void;
  backToSchedule?: Schedule;
  cancelChangeConfirm: (callback: any) => void;
  handleChangeColorClick: (eventItem: any) => void;
}) => {
  const [options, setOptions] = useState<any>();

  const [scheduleEditorAction, setScheduleEditorAction] = useState<
    'Create' | 'Edit'
  >('Create');
  const [isOpenScheduleEditor, setIsOpenScheduleEditor] = useState(false);

  const [scheduleItems, setScheduleItems] = useState<EventInput[]>([]);
  const [selectedSchedules, setSelectedSchedules] = useState<Schedule[]>();

  useEffect(() => {
    setScheduleItems(
      editingSchedules.map(schedule => {
        return {
          id: schedule?.id?.toString(),
          groupId: uuidv4(),
          title: `${schedule.content_object?.name}`,
          start: schedule.start_at,
          end: schedule.end_at,
          backgroundColor: schedule.content_object?.label_color || 'auto',
          extendedProps: {
            scheduleData: {
              ...schedule,
              type: schedule.content_type?.model,
            },
          },
        };
      })
    );
  }, [editingSchedules, selectedSchedules]);

  useEffect(() => {
    if (backToSchedule) {
      setSelectedSchedules([backToSchedule]);
      setScheduleEditorAction('Edit');
      setIsOpenScheduleEditor(true);
    }
  }, [backToSchedule]);

  const handleAddClick = (selectedSlot: any) => {
    setSelectedSchedules(undefined);
    if (selectedSlot) {
      setSelectedSchedules([
        {
          start_at: selectedSlot.start.toISOString(),
          end_at: selectedSlot.end.toISOString(),
          duration: moment
            .duration(
              moment(selectedSlot.end).diff(moment(selectedSlot.start)),
              'milliseconds'
            )
            .asSeconds(),
          priority: 10,
        },
      ]);
    }
    setScheduleEditorAction('Create');
    setIsOpenScheduleEditor(true);
  };

  const editSchedule = (action: string, schedule: Schedule) => {
    if (action === 'Create')
      setEditingSchedules([...editingSchedules, { ...schedule, id: -1 }]);
    else
      setEditingSchedules([
        ...editingSchedules.map(s => {
          if (s.id == schedule.id && s.editing) {
            if (s.editing) schedule.editing = false;
            schedule['id'] = -1;
            return schedule;
          } else return s;
        }),
      ]);
    setIsOpenScheduleEditor(false);
  };

  useEffect(() => {
    setOptions({
      headerToolbar: {
        left: '',
        center: 'title',
        right: 'prevBtn,nextBtn',
      },
      slotLabelFormat: {
        hour: 'numeric',
        minute: '2-digit',
        omitZeroMinute: false,
        meridiem: 'short',
      },
      slotLabelInterval: '00:30:00',
      expandRows: true,
      nowIndicator: true,
      editable: true,
      eventBackgroundColor: 'rgba(52,216,235,.5)',
      displayEventTime: false,
      dayHeaderClassNames: 'fc-day-clickable',
      eventOrder: '-priority',
      initialDate: backToSchedule ? backToSchedule.start_at : new Date(),
    });
  }, [backToSchedule]);

  return (
    <>
      <EuiSpacer />

      {options && scheduleItems && (
        <TimeGrid
          calendarOptions={options}
          events={scheduleItems}
          setDateRange={setDateRange}
          setEditingEventsItem={setEditingSchedules}
          handleEditClick={(eventItem: any) => {
            setEditingSchedules([
              ...editingSchedules.map(s => {
                if (s.start_at == eventItem.start_at) {
                  s['editing'] = true;
                }
                return s;
              }),
            ]);
            setSelectedSchedules([eventItem]);
            setScheduleEditorAction('Edit');
            setIsOpenScheduleEditor(true);
          }}
          handleAddClick={handleAddClick}
          cancelChangeConfirm={cancelChangeConfirm}
          handleChangeColorClick={handleChangeColorClick}
        />
      )}

      {isOpenScheduleEditor && (
        <EditorModal
          screen={screen}
          action={scheduleEditorAction}
          schedule={selectedSchedules && selectedSchedules[0]}
          editSchedule={editSchedule}
          onClose={() => {
            setIsOpenScheduleEditor(false);
          }}
        />
      )}
    </>
  );
};

export default TimeGridEditor;
